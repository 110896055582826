import './customLoader.less';
export interface loaderPropsParams {
  size: 'small' | 'default' | 'large';
  loaderTitle?: string;
}

const CustomLoader = (loaderProps: loaderPropsParams) => {
  return (
    <div>
      <div className="customLoaderRoot">
      <div className="loader"><div></div><div></div><div></div><div></div></div>
        {loaderProps.loaderTitle && (
          <div className={loaderProps.size === 'large' ? 'mt-1' : ''}>
            {loaderProps.loaderTitle && (
              <h3>
                {loaderProps.loaderTitle}
              </h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomLoader;
