import {
  sessionReqType,
  sessionRespType,
  updateOrderReqType,
} from '../../types/session';
import { APIPath } from '../../utils/apiConstants';
import { network } from '../../utils/network';

export const CheckoutService = {
  session: async ({
    orderId,
  }: sessionReqType): Promise<sessionRespType | undefined> => {
    try {
      const url = `${APIPath.SESSION_URL}?timestamp=${new Date().getTime()}`;
      const response = await network('GET', url, { params: { orderId } });
      return response.data;
    } catch (error: any) {
      throw new Error(error?.message || 'Failed to create payment session!');
    }
  },

  updateOrder: async ({
    sessionId,
    paymentStatus,
  }: updateOrderReqType): Promise<{ redirectUrl: string } | undefined> => {
    try {
      const url = `${APIPath.UPDATE_ORDER_URL}`;
      const response = await network('PATCH', url, {
        params: { sessionId, paymentStatus },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error?.message || 'Failed to create payment session!');
    }
  },
};
