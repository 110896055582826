export const envConfig = {
  REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL,
  REACT_APP_ADYEN_ENVIORNMENT: process.env.REACT_APP_ADYEN_ENVIORNMENT,
  REACT_APP_ADYEN_CLIENT_KEY: process.env.REACT_APP_ADYEN_CLIENT_KEY,
  REACT_APP_ADYEN_HMAC_KEY: process.env.REACT_APP_ADYEN_HMAC_KEY,
  REACT_APP_ADYEN_API_KEY: process.env.REACT_APP_ADYEN_API_KEY,
  REACT_APP_BACKEND_ADMIN_SERVER_URL:
    process.env.REACT_APP_BACKEND_ADMIN_SERVER_URL,
  REACT_APP_APP_KEY: process.env.REACT_APP_APP_KEY,
  REACT_APP_SHOPLINE_FRONT_END_URL:
    process.env.REACT_APP_SHOPLINE_FRONT_END_URL,
  REACT_APP_SHOPLINE_AUTHORIZE_URL:
    process.env.REACT_APP_SHOPLINE_AUTHORIZE_URL,
  REACT_APP_PORTAL_URL: process.env.REACT_APP_PORTAL_URL
};
