import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Lazy load the components
const Login = lazy(() => import('../pages/login'));
const BindStore = lazy(() => import('../pages/bind-store/Index'));
const Checkout = lazy(() => import('../pages/checkout'));
const ErrorPage = lazy(() => import('../pages/error-page'));
const AppBridge = lazy(() => import('../pages/app-bridge'));
const Callback = lazy(() => import('../pages/callback'));
const RedirectAdyen = lazy(() => import('../pages/redirect-adyen'));

// Define a fallback component for loading state

const Loading = () => <div></div>;
export default function Root() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/app-bridge" element={<AppBridge />} />
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/bind-store" element={<BindStore />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/redirect-adyen" element={<RedirectAdyen />} />
      </Routes>
    </Suspense>
  );
}
