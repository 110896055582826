import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomButtonLoader } from '../../components/custom-buttom-with-loader';
import './ErrorPage.less';
import { useCallback, useState } from 'react';
import { PaymentStatus } from '../../utils/constants';
import { CheckoutService } from '../../services/api/checkout';
import CustomLoader from '../../components/custom-loader';
export default function ErrorPage() {
  const navigate = useNavigate();
  const [queryParam] = useSearchParams();
  const sessionId = queryParam.get('sessionId');
  const reason = queryParam.get('reason');
  const [apiLoader, setApiLoader] = useState({ loading: false, message: '' });

  const goBack = useCallback(async () => {
    if (!sessionId) {
      navigate(-2);
      return;
    }

    try {
      setApiLoader({ loading: true, message: 'Navigating back, Please wait!' });
      const result = await CheckoutService.updateOrder({
        sessionId,
        paymentStatus: PaymentStatus.CANCELLED,
      });
      if (result?.redirectUrl) {
        window.location.replace(result?.redirectUrl);
      } else {
        navigate(-2);
      }
    } catch (error) {
      setApiLoader({ loading: false, message: '' });
      navigate(-2);
    }
  }, [sessionId]);

  const retry = () => {
    navigate(-1);
  };

  return (
    <div id="error-page" className="adyen-container">
      <div id="error-page-child" className="adyen-child-container">
        <h1>Oops!</h1>
        <p className="error-text warning">
          {reason ?? 'Sorry, an unexpected error has occurred.'}
        </p>
        {apiLoader.loading ? (
          <CustomLoader size="large" loaderTitle={apiLoader.message} />
        ) : (
          <>
            <CustomButtonLoader
              isLoading={false}
              className="error-button"
              onClick={goBack}
              type="primary"
              size="large"
              htmlType="button"
              isBlock={true}
            >
              Go back to checkout page
            </CustomButtonLoader>
            <CustomButtonLoader
              className="error-button"
              isLoading={false}
              onClick={retry}
              type="primary"
              size="large"
              htmlType="button"
              isBlock={true}
            >
              Retry Payment
            </CustomButtonLoader>
          </>
        )}
      </div>
    </div>
  );
}
