import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_METHODS } from './constants';
import { envConfig } from './envConfig';

export interface PayloadType {
  signal?: AbortSignal;
  body?: object;
  params?: object;
}

export interface ErrorResponseHandler {
  status: number;
  data: null;
  success: boolean;
  message: string;
}

const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
});

const handleAxiosResponse = (res: AxiosResponse) => {
  if (!res) {
    throw new Error('Network Error');
  }

  const { status, data } = res;

  const defaultResponse = {
    status,
    data: null,
    success: false,
    message: '',
  };

  if (status === 401) {
    return {
      ...defaultResponse,
      errors: data.errors || null,
    };
  }
  return {
    ...defaultResponse,
    data: data || null,
    success: status === 200,
  };
};

const handleAxiosError = (err: any): ErrorResponseHandler => ({
  status: err.response?.status || 500,
  data: null,
  message: err?.response?.data?.returnMessage || err.message,
  success: false,
});

export const network = async (
  method: keyof typeof API_METHODS,
  url: string,
  payload?: PayloadType,
  customHeaders?: object
) => {
  const apiURL = `${envConfig.REACT_APP_SERVER_URL}${url}`;

  //TOKEN SET IN HEADER
  const token = localStorage.getItem('jwt_access_token');

  const headers = {
    ...getDefaultHeaders(),
    ...(customHeaders || {}),
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
  };

  const configs: AxiosRequestConfig = {
    headers,
    params: payload?.params,
    data: payload?.body,
    signal: payload?.signal,
  };
  try {
    const response = await axios.request({
      method,
      url: apiURL,
      ...configs,
    });
    return handleAxiosResponse(response);
  } catch (err) {
    throw handleAxiosError(err as AxiosError);
  }
};

export const convertParamsToUrlQuery = (
  url: string,
  params: { [x: string]: string | number | boolean }
) => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');

  return `${url}?${queryString}`;
};
