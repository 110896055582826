export const API_METHODS: {
  GET: 'GET';
  PUT: 'PUT';
  POST: 'POST';
  DELETE: 'DELETE';
  PATCH: 'PATCH';
} = {
  // API methods
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export enum AppUserType {
  VENDOR = 'VENDOR',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  EXTERNAL = 'EXTERNAL',
}

export enum PaymentStatus {
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  AUTHORIZED = 'AUTHORIZED',
}

