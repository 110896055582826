import React, { ReactNode } from 'react';
import './custom-button-loader.less';

export const CustomButtonLoader = ({
  size,
  type,
  htmlType,
  className,
  btnText,
  isLoading,
  children,
  onClick,
  disabled,
  isBlock,
}: {
  size: string;
  type: string;
  htmlType: "submit" | "reset" | "button";
  className?: string;
  btnText?: string | ReactNode;
  isLoading?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isBlock?: boolean;
}) => {
  return (
    <button
      className={`btn ${size} ${type} ${isBlock ? 'block' : ''} ${className}`}
      onClick={onClick}
      disabled={disabled || isLoading ? true : false}
      type={htmlType}
    >
      {btnText} {children}
    </button>
  );
};
